import ElementPlus from 'element-plus'
import '../element-variables.scss'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import {Edit, Delete, Plus, Close} from '@element-plus/icons-vue'

export default (app) => {
    app.use(ElementPlus, {locale})
    app.component('edit', Edit)
    app.component('delete', Delete)
    app.component('plus', Plus)
    app.component('close',Close)
}
