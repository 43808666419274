<template>
  <el-aside
      style="text-align: left; height: 100%"
      width="200px"
  >
    <el-menu class="el-menu-vertical-demo" :default-active="current_router" :router="true"
             style="height: 100%;background-color: #ffffff;" :unique-opened="true">
      <template v-for="(item,index) in routes" :key="index">
        <el-sub-menu :index="item.path">
          <template #title>
            <span>{{ $t(item.title) }}</span>
          </template>
          <template v-for="(value, key) in item.children" :key="key">
            <el-menu-item :index="value.path" @click="handleClickMenu(value.children)">{{
                $t(value.title)
              }}
            </el-menu-item>
          </template>
        </el-sub-menu>
      </template>
    </el-menu>
  </el-aside>
</template>
<script>
import tool from "../../../utils/tool";

export default {
  name: "SideBar",
  data() {
    return {
      routes: []
    }
  },
  computed: {
    current_router() {
      return this.$route.href
    }
  },
  mounted() {
    const role = localStorage.getItem('role')
    if (tool.isEmpty(role)) {
      this.$message.error(this.$t('header.re_login'))
      this.$router.push('/login')
      return false
    }
    this.routes = JSON.parse(role)
  },
  methods: {
    handleClickMenu(data) {
      let action = []
      data.forEach(v => {
        action.push(v.value)
      })
      localStorage.setItem('page-action', JSON.stringify(action))
      this.$store.commit('SET_PAGE_ACTION', action)
    }
  }
}
</script>
<style scoped>
</style>
