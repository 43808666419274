<template>
  <div>
    <el-header id="elheader">
      <div class="logo" style="text-align:center">
        <img :src="client_logo" style="height: 48px; width: auto;margin-top:6px;" alt/>
      </div>

      <div style="flex:1;display:flex;">
<!--        <el-menu-->
<!--            :default-active="current_router"-->
<!--            class="el-menu-demo"-->
<!--            mode="horizontal"-->
<!--            background-color="#ffffff"-->
<!--            text-color="#000000"-->
<!--            active-text-color="#ffd04b"-->
<!--            :ellipsis="false"-->
<!--            :router="true"-->
<!--        >-->
<!--          <template v-for="(item,index) in routes" :key="index">-->
<!--            <el-menu-item :index="item.path">{{ $t(item.title) }}</el-menu-item>-->
<!--          </template>-->
<!--          <el-menu-item index="/screen">{{ $t('menu.data_panel') }}</el-menu-item>-->
<!--        </el-menu>-->
        <div class="outerlink">
<!--          <a @click="handleScreen">{{ $t('menu.data_panel') }}</a>-->
          <el-link type="primary" underline href="/screen">{{ $t('menu.data_panel') }}</el-link>
        </div>
        <!--      <div class="outerlink">-->
        <!--        <span @click="handleScreen">数据面板</span>-->
        <!--      </div>-->
      </div>
      <div class="usermessage">
        <el-dropdown style="margin-right: 15px">
          <span class="language">{{ locale === 'en' ? 'English' : '中文' }}</span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="中文" @click="changeLang('zh-cn')">中文</el-dropdown-item>
              <el-dropdown-item command="Englist" @click="changeLang('en')">English</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown>
        <span class="el-dropdown-link">
          {{ username }}
        </span>
          <template v-slot:dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="handleCenter">{{ $t('header.personal') }}</el-dropdown-item>
            </el-dropdown-menu>
            <el-dropdown-menu>
              <el-dropdown-item @click="handleOut">{{ $t('header.logout') }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </el-header>
  </div>
</template>
<script>
import tool from "../../../utils/tool";
import {languageSet} from '@/utils/watch-local-storage.js'

export default {
  name: "Header",
  data() {
    return {
      routes: [],
      activeIndex2: "1",
      username: "",
      linkDialog: false,
      client_logo: "",
      locale: null
    };
  },
  computed: {
    current_router() {
      const url = this.$route.href.split("/");
      return "/" + url[1];
    }
  },
  mounted() {
    let logo = tool.getLocalStorage('client_logo')
    if (logo === 'null') {
      this.client_logo = "https://adminapi.yztc2022.com/logo.png";
    } else {
      this.client_logo = logo;
    }
    if (tool.getLocalStorage("client_name") !== "") {
      document.title = this.$t('login.system') + " - " + tool.getLocalStorage("client_name");
    }
    this.username = tool.getLocalStorage("user_name");
    const role = localStorage.getItem("role");
    if (tool.isEmpty(role)) {
      this.$message.error(this.$t('header.re_login'));
      this.$router.push("/login");
      return false;
    }
    this.routes = JSON.parse(role);
    this.locale = languageSet()
  },
  methods: {
    changeLang(val) {
      this.locale = val === 'zh-cn' ? 'zhCN' : val;
      localStorage.setItem("locale", this.locale);
      window.location.reload()
    },
    handleOut() {
      tool.removeLocalStorage("token");
      tool.removeLocalStorage("user_name");
      tool.removeLocalStorage("role");
      // window.location.reload();
      this.$store.commit('SET_LOGIN_STATUS', 0)
      this.$router.replace("/login");
    },
    handleCenter() {
      this.$router.replace("/user/center");
    },
    handleDltrack() {
      window.open('http://dltrackings.5csss.com/public/OXecxhAKwk.php', '_blank')
    },
    outerLink() {
      if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
        if (
            window.navigator.userAgent.indexOf("WOW64") > -1 ||
            !window.navigator.webkitPersistentStorage
        ) {
          window.open("http://horm.5csss.com/", "__blank");
        } else {
          this.linkDialog = true;
        }
      }
    },
    handleScreen() {
      this.$router.push('/screen')
    },
    copyLink() {
      this.copyText("http://horm.5csss.com/", () => {
        this.$message({
          message: "复制成功",
          type: "success"
        });
      });
    },
    copyText(text, callback) {
      // text: 要复制的内容， callback: 回调
      var tag = document.createElement("input");
      tag.setAttribute("id", "cp_hgz_input");
      tag.value = text;
      document.getElementsByTagName("body")[0].appendChild(tag);
      document.getElementById("cp_hgz_input").select();
      document.execCommand("copy");
      document.getElementById("cp_hgz_input").remove();
      if (callback) {
        callback(text);
      }
    }
  }
};
</script>
<style>
.outerlink {
  height: 60px;
  line-height: 60px;
  cursor: pointer;
}

.outerlink a {
  text-decoration: none;
  //color: #000000;
  display: inline-block;
  height: 60px;
  line-height: 60px;
  padding: 0px 15px;
}

.outerlink a:hover {
  background: rgb(204, 204, 204);
}
</style>
