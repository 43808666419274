import {createI18n} from 'vue-i18n'
import messages from './index'

const locale = localStorage.getItem("locale")
    ? localStorage.getItem("locale")
    : "zhCN"
const i18n = createI18n({
    legacy: false,
    locale,
    messages,
});
export default function (app) {
    app.use(i18n);
}
