<template>
  <div>
    <Header/>
    <el-container>
      <SideBar/>
      <el-main>
        <el-header style="text-align: right; font-size: 12px">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :key="index" :to="{ path: item.path }" v-for="(item,index) in breadcrumbs">
              {{ $t(item.meta.title) }}
            </el-breadcrumb-item>
          </el-breadcrumb>
        </el-header>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Header from "./components/Header";
import SideBar from "./components/SideBar";

export default {
  name: "Layout",
  data() {
    return {
      matches: [],
      breadcrumbs: []
    }
  },
  components: {
    Header,
    SideBar
  },
  watch: {
    '$route.path'() {
      this.getBreadcrumbs()
    }
  },
  mounted() {
    this.getBreadcrumbs()
  },
  methods: {
    getBreadcrumbs() {
      this.breadcrumbs = this.$route.matched
    }
  }
};
</script>
<style>
.el-container {
  height: 94%;
}

#elheader {
  height: 60px;
  display: flex;
  padding: 0px !important;
  /*background-color: rgb(84, 92, 100);*/
}

.logo {
  width: 200px;
}

.usermessage {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.el-aside {
  height: 100%;
  display: block;
  position: relative;
  overflow-y: scroll;
  /*background-color: #3a3c41 !important;*/
  /*color: rgb(191, 203, 217) !important;*/
}

.el-menu--inline {
  /*background-color: #3a3c41 !important;*/
  /*color: #fff !important;*/
}

.el-submenu__title {
  /*color: #fff !important;*/
}

.el-menu-item {
  /*color: #fff !important;*/
}

.el-submenu__title:hover {
  /*background-color: #3a3c41 !important;*/
}

.el-menu-item:hover,
.el-menu-item:focus {
  outline: none;
  /*background-color: #3a3c41 !important;*/
}
</style>
