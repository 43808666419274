<template>
  <el-config-provider :locale="locale">
    <router-view></router-view>
    <div v-show="false">{{ checkLogin }}</div>
  </el-config-provider>
</template>
<script>
import tool from './utils/tool'
import {languageSet} from "./utils/watch-local-storage.js"
import zhCn from "element-plus/dist/locale/zh-cn.mjs"; // 中文包
import en from "element-plus/dist/locale/en.mjs"; // 英文包
let language = languageSet()
export default {
  name: "App",
  data() {
    return {
      socket: null,
      alarm: {},
      notice: 1,
      voice: 1,
      audio: null
    };
  },
  computed: {
    checkLogin() {
      if (this.$store.state.isLogin === 0 && this.socket != null) {
        this.socket.close()
      }
      if (this.$store.state.isLogin === 1) {
        this.loadSocket();
      }
      return ''
    },
    locale() {
      return language !== "en" ? zhCn : en
    }
  },
  mounted() {
    const alarm = localStorage.getItem('alarm')
    if (!tool.isEmpty(alarm)) {
      this.alarm = JSON.parse(alarm)
    }
    this.audio = new Audio('https://admin.yztc2022.com/alarm.mp3')
    this.audio.muted = false
    this.audio.preload = "auto"
    window.addEventListener("setItemEvent", function (e) {
      // 监听localStorage  locale 值的变化
      if (e.key === "locale") {
        language = e.newValue
      }
    })
  },
  methods: {
    loadSocket() {
      const me = this
      this.socket = new WebSocket("wss://adminapi.yztc2022.com");
      this.socket.onopen = () => {
        this.socket.send(JSON.stringify({
          code: 401,
          uid: tool.getLocalStorage('uid')
        }))
      };
      setInterval(() => {
        this.socket.send(JSON.stringify({
          code: 202
        }))
      }, 10000)
      this.socket.onmessage = event => {
        const data = JSON.parse(event.data);
        if (data.code === 100) {
          if (this.alarm.notice == 2) {
            this.notice = 2
            this.voice = 2
          } else if (this.alarm.notice == 1) {
            switch (data.type) {
              case 1:
                this.notice = this.alarm.enter_notice
                this.voice = this.alarm.enter_voice
                break;
              case 2:
                this.notice = this.alarm.leave_notice
                this.voice = this.alarm.leave_voice
                break;
              case 3:
                this.notice = this.alarm.sos_notice
                this.voice = this.alarm.sos_voice
                break;
              case 4:
                this.notice = this.alarm.volt_notice
                this.voice = this.alarm.volt_voice
                break;
              case 5:
                if (data.data.indexOf('体温异常-偏低') > -1) {
                  this.notice = this.alarm.temp_down_notice
                  this.voice = this.alarm.temp_down_voice
                } else {
                  this.notice = this.alarm.temp_up_notice
                  this.voice = this.alarm.temp_up_voice
                }
                break;
              case 6:
                if (data.data.indexOf('心率异常-偏低') > -1) {
                  this.notice = this.alarm.heart_down_notice
                  this.voice = this.alarm.heart_down_voice
                } else {
                  this.notice = this.alarm.heart_up_notice
                  this.voice = this.alarm.heart_up_voice
                }
                break;
              case 7:
                if (data.data.indexOf('血压异常-收缩压偏低') > -1) {
                  this.notice = this.alarm.systolic_down_notice
                  this.voice = this.alarm.systolic_down_voice
                } else if (data.data.indexOf('血压异常-收缩压偏高') > -1) {
                  this.notice = this.alarm.systolic_up_notice
                  this.voice = this.alarm.systolic_up_voice
                } else if (data.data.indexOf('血压异常-舒张压偏低') > -1) {
                  this.notice = this.alarm.diastolic_down_notice
                  this.voice = this.alarm.diastolic_down_voice
                } else {
                  this.notice = this.alarm.diastolic_up_notice
                  this.voice = this.alarm.diastolic_up_voice
                }
                break;
              case 8:
                this.notice = this.alarm.take_off_notice
                this.voice = this.alarm.take_off_voice
                break;
            }
          }
          if (this.notice === 1) {
            this.$notify({
              title: "告警提示",
              message: data.data,
              duration: 30000,
              type: "warning",
              onClick() {
                me.$router.push({
                  path: "/data/home",
                  query: {
                    imei: data.imei
                  }
                });
              }
            });
          }
          if (this.voice === 1) {
            this.audio.play()
          }
        }
      };
      this.socket.onclose = event => {
        console.log("websocket-close", event);
      };
      this.socket.onerror = event => {
        console.log("websocket-error", event);
      };
    },
  }
};
</script>
<style>
html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  font-size: 14px;
  /*overflow: hidden;*/
}

#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  transform-origin: 0 0;
  /*width: 1920px;*/
  /*height: 1080px;*/
}

#app ul,
ol li {
  list-style: none;
}

div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  padding: 0px;
  margin: 0px;
  font-weight: normal;
}

/* 修改element表头错位的问题 */
body .el-table th.gutter {
  display: table-cell !important;
}

body .el-table colgroup.gutter {
  display: table-cell !important;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

/* 修改element表头错位的问题 end */
</style>
