const en = {
    login: {
        title: 'Login Page',
        system: 'Smart device management platform',
        username: 'Account',
        password: 'Password',
        button: 'Login',
        user_empty: 'The account cannot be empty',
        user_length: 'Account 3-10 digits',
        password_empty: 'Password cannot be empty',
        password_length: 'Password 5-16 bits',
    },
    index: {
        title: 'Home page',
        panel: 'panel',
        device_stat: 'Device statistics',
        more: 'More',
        device_total: 'Device total',
        new_add_today: 'New add today',
        online: 'Online',
        offline: 'Offline',
        stripping_outline: 'Stripping offline',
        alarm_info: 'Alarm information',
        username: 'Username',
        device: 'Device IMEI',
        battery_level: 'Battery level',
        online_status: 'Online status',
        alarm_type: 'Alarm type',
        alarm_content: 'Alarm content',
        alarm_time: 'Alarm time',
        positioning: 'Positioning',
        device_type: 'Device type',
        device_name: 'Device name',
        location_info: 'Location Information',
        comm_time: 'Comm time',
    },
    device: {
        input_keywords: 'Please fill in filter keyword',
        add_user: 'Add/Edit Users',
        company_name: 'Company Name',
        company_empty: 'Company name cannot be empty',
        parent_customer: 'Parent Customer',
        company_logo: 'Company Logo',
        size_desc: 'Size Description: Width 400px, Height 120px',
        data_push: 'Data push configuration',
        close: 'Close',
        testing: 'Testing Environment',
        official: 'Official Environment',
        input_testing: 'Please enter the testing environment address',
        input_official: 'Please enter the official environment address',
        cancel: 'Cancel',
        confirm: 'Confirm',
        image_cropping: 'Image cropping',
        devices: 'Device list',
        device_status: 'Device status',
        inquire: 'Inquire',
        reset: 'Reset',
        export: 'Export',
        add: 'Add to',
        batch_add: 'Batch add devices',
        assign: 'Assign device',
        delete: 'Delete',
        co_search: 'Co search',
        device_found: 'device found',
        view: 'view',
        not_found_device: 'No device IMEI number found',
        vesting: 'Vesting customer',
        creation_time: 'Creation time',
        last_comm_time: 'Last comm time',
        operating: 'Operating',
        parameter: 'Parameter',
        edit: 'Edit',
        add_devices: 'Add/Edit Devices',
        device_empty: 'The device number cannot be empty',
        device_type_empty: 'The device type cannot be empty',
        import_template: 'Import template',
        download: 'Download',
        input_import: 'Please upload the import file',
        import_file: 'Import file',
        upload: 'Upload',
        only_files: 'Only xls/xlsx files',
        tips: 'Tips',
        success_import: 'Successfully imported',
        error_import: 'Among them, there are errors',
        not_import: 'Not imported',
        import_tip: 'Click "Confirm" to save the list of incorrect data, and "Cancel" to exit.',
        customer_empty: 'Target customer cannot be empty',
        select_customer: 'Select Customers',
        device_not_found: 'No device IMEI found',
        continue_search: 'Continue to search',
        copy_all: 'Copy All',
        device_params: 'Device parameter settings',
        positioner: 'Positioner',
        sleep_mode: 'Sleep Mode',
        open: 'Open',
        night_mode: 'Night Mode',
        data_upload_interval: 'Data upload interval',
        interval_time: 'Interval time',
        minute: 'minute',
        second: 'second',
        minutes_error: 'Cannot be less than 4 minutes',
        nb_note: 'Note: This configuration will take effect next time the data is uploaded',
        setting_note: 'Note: The content of each module has been set and needs to be individually clicked to send it to take effect',
        second_tip: 'Issuing interval in seconds: cannot be less than 240 seconds',
        position_note: 'Note: GPS positioning is preferred for outdoor environments; Prioritize indoor environment with WIFI positioning',
        wifi_priority: 'WIFI priority',
        gps_priority: 'GPS priority',
        position_time: 'Positioning Time',
        active_config: 'Timed activation configuration',
        status: 'Status',
        start_time: 'Start Time',
        time_example: 'Time division format, for example:',
        time: 'Time',
        end_time: 'End Time',
        prompt_config: 'Prompt configuration immediately',
        remind_type: 'Reminder Type',
        one_sound: 'Just one sound',
        keep_sound: 'Continuous beeps, need to be turned off by pressing the button',
        audio_type: 'Audio Type',
        urgent_sound: 'Urgent prompt sound',
        smooth_sound: 'Smooth prompt sound',
        vibration: 'Vibration only',
        time_alarm: 'Timed alarm configuration',
        send: 'Send',
        heart_upload: 'Heart rate upload time, input range: 00:00-23:59',
        allow_time: 'Allow measurement time',
        prohibit_time: 'Prohibit measuring time',
        heart_threshold: 'Heart rate alarm threshold, minimum threshold range: 1-50; Maximum threshold range:>=90',
        min_threshold: 'Minimum Threshold',
        input_min_threshold: 'Please enter the minimum threshold',
        max_threshold: 'Maximum threshold',
        input_max_threshold: 'Please enter the maximum threshold',
        distribute_note: 'Distribute intervals in minutes, with a measurement interval range of 1-60; Upload interval: 1-180',
        measure_interval: 'Measurement time interval',
        measure_interval_error: 'Please enter the measurement time interval',
        upload_interval: 'Upload interval',
        upload_interval_error: 'Please enter the upload interval',
        select_time: 'Please select a time',
        interval_minute: 'Distribute intervals in minutes',
        wifi_note: 'Note: Default WIFI priority',
        only_gps: 'GPS only',
        sos_note: 'After setting the SOS number, the user presses the SOS button on the device and will dial the phone number',
        sos: 'SOS number',
        reboot: 'Reboot',
        restore: 'Restore',
        instant: 'Instant positioning',
        gps_timeout: 'GPS timeout time',
        gps_timeout_second: 'Send GPS timeout time in seconds',
        remote: 'Remote shutdown',
        low_battery_alarm: 'Low battery alarm trigger',
        alarm_time_note: 'Description: Alarm Time - On/Off - Frequency - Type. On/Off: 0 Off 1 On Frequency: 1 Once 2 Every Day 3 Custom Day - Week 6 Type:\n' +
            '                            Sunday to Saturday\n' +
            '                            For example：08:10-1-1,08:10-1-2,08:10-1-3-0111110',
        switch: 'Switch',
        cycle: 'Cycle',
        sunday: 'Sunday',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        step_trigger: 'Step counting trigger',
        phone_book: 'The G36 phone book setting actually includes 2 instructions for setting the phone book',
        name: 'Name',
        number: 'Number',
        unit: 'Unit: seconds, range',
        heart_interval: 'Heart rate upload interval',
        measure_heart: 'Measuring heart rate',
        find: 'Find devices',
        sleep_setting: 'Sleep time period setting',
        temp_note: 'Real time measurement protocol for body temperature (BT=2, new version)',
        temp_interval: 'Temperature upload interval',
        unit_hour: 'Unit hour, value: 1-12 (not reported in night mode)',
        custom_instruction: 'Custom Instructions',
        instruction_content: 'Instruction content',
        instruction_error: 'Please enter custom instructions',
        falldown_setting: 'Falldown alarm setting',
        call_emergency: 'Call emergency contacts',
        falldown_note: 'Numerical value: number, range: 1-8. The smaller the number, the higher the sensitivity',
        sensitivity: 'Current sensitivity ',
        suggest: 'Input suggestion 5/6',
        reject_phone: 'Reject switch for unfamiliar phone calls',
        timer_on_off: 'Timer on/off',
        position_mode: 'Positioning Mode',
        on_time: 'On time',
        off_time: 'Off time',
        wifi_watch: 'WIFI Watch',
        allocate_success: 'Allocation successful',
        replicating: 'Replicating Success',
        tip: 'Tip',
        delete_note: 'This operation will permanently delete the data. Do you want to continue?',
        upload_error: 'Uploading images can only be in JPG/PNG format!',
        upload_note: 'The size of the uploaded image cannot exceed 2MB!',
        delete_success: 'Delete successful',
        upload_success: 'Upload successful',
        upload_failed: 'Upload failed',
    },
    detail: {
        overview: 'Overview',
        admin: 'Administrator',
        software: 'Software version',
        hardware: 'Hardware version',
        signal: 'Signal',
        volt: 'Voltage',
        step: 'Step count',
        warning: 'Warning',
        last_warning: 'Last warning',
        last_alarm_time: 'Last alarm time',
        measurements: 'Measurements',
        measure_time: 'Measure time',
        blood: 'Blood pressure',
        hrv: 'HRV pressure',
        pressure: 'ressure',
        heart: 'Heart rate',
        temp: 'Temperature',
        diastolic: 'Diastolic blood pressure',
        systolic: 'Systolic blood pressure',
        oxygen: 'Blood oxygen',
        lon: 'Longitude',
        lat: 'Latitude',
        location: 'Location',
        position_method: 'Positioning method',
        exercise: 'Exercise data',
        calorie: 'Calorie',
        move_distance: 'Movement distance',
        average_step: 'Average steps',
        jump_times: 'Jumping times',
        jump_height: 'Jumping height',
        alarm_list: 'Alarm list',
        detail: 'Detail',
        alarm_location: 'Alarm location',
        trajectory: 'Trajectory',
        location_failed: 'Device localization failed',
    },
    notice: {
        enter_imei: 'Please enter IMEI',
        message: 'Notification message',
        input_message: 'Please fill in notification message',
        submit: 'submit',
    },
    alarm: {
        enter_fence: 'Entering fence',
        leaving_fence: 'Leaving fence',
        sos_alarm: 'SOS alarm',
        low_battery: 'Low battery',
        temperature_alarm: 'Abnormal temperature',
        heart_alarm: 'Abnormal heart rate',
        blood_alarm: 'Abnormal blood pressure',
        removing_alarm: 'Abnormal removing',
        falldown_alarm: 'Falldown alarm',
        reminding_alarm: 'Motionless reminding',
        trigger_alarm: 'Button trigger/Drawstring trigger',
        reset_alarm: 'Alarm reset',
        dismantle_alarm: 'Anti dismantling alarm',
        pressure_alarm: 'HRV abnormal',
        main: 'Main switch',
        notice: 'Notice switch',
        sound: 'Sound switch',
        security: 'Security alarm',
        device: 'Device alarm',
        health: 'Health alarm',
        low_temp: 'Low temperature',
        high_temp: 'High temperature',
        low_heart: 'Low heart rate',
        high_heart: 'High heart rate',
        high_systolic: 'High systolic pressure',
        low_systolic: 'Low systolic pressure',
        high_diastolic: 'High diastolic pressure',
        low_diastolic: 'Low diastolic pressure',
        upload: 'Upload settings',
        confirm: 'Confirm to save the alarm settings, do you want to continue?',
        open: 'Open',
        close: 'Close',
        alarm_value: 'Alarm value',
        safe_range: 'Safety scope',
        handle: 'Handle',
        desc: 'Handling instructions',
        handle_time: 'Processing time',
        input_desc: 'Please enter the processing description',
        select_alarm: 'Please select alarm information first',
        batch_handle: 'Batch processing',
    },
    fence: {
        title: 'fence',
        device: 'Device',
        userinfo: 'User information',
        add: 'Adding fence',
        name: 'Fence name',
        radius: 'Radius',
        alarm: 'Fence alarm',
        type: 'Fence type',
        normal: 'Water fence-Normal',
        flood: 'Water Fence - Flood Season',
        safety: 'Safety fence',
        construction: 'Construction fence',
        alarm_level: 'Alarm level',
        red: 'red',
        orange: 'orange',
        yellow: 'yellow',
        duration: 'Alarm duration',
        status: 'Fence status',
        creator: 'Creator',
        shape: 'Fence shape',
        circle: 'Circular fence',
        polygon: 'Polygonal fence',
        details: 'Fence details',
        alarm_type: 'Alarm Type',
        enter: 'Enter',
        leave: 'Leave',
        enter_leave: 'Enter&Leave',
        on: 'on',
        off: 'off',
        select_shape: 'Please select the fence shape',
        select_type: 'Please select the fence type',
        select_name: 'Please enter the fence name',
        select_alarm: 'Please select the alarm type',
        select_period: 'Please select the alarm period',
        select_level: 'Please select the alarm level',
        select_device: 'Please select device information',
        select_detail: 'Please enter fence details',
        draw_fence: 'Please draw fence information',
        success: 'Operation successful',
        clear: 'Clean Up',
    },
    health: {
        title: 'Health',
        select_date: 'Select Date',
        no_data: 'No data',
        breathe: 'Breathe',
        frequency: 'Frequency',
        times: 'Times',
        sleep: 'Sleep',
        sleep_duration: 'Sleep Duration',
        shallow: 'Shallow sleep duration',
        deep: 'Deep Sleep duration',
        clear: 'Clear headed duration',
        shallow_sleep: 'Shallow sleep',
        deep_sleep: 'Deep Sleep',
        clear_sleep: 'Clear headed',
        sleep_status: 'Sleep Status',
    },
    group: {
        alarm_interval: 'Set alarm interval',
        issue_time: 'Issuing time',
        operator: 'Operator',
        alarm_setting: 'Alarm settings',
        alarm_object: 'Alarm object',
        alarm_type: 'Select alarm type',
        normal_range: 'Normal range',
        max: 'Maximum value',
        min: 'Minimum value',
        select_device: 'Select device',
        alarm_note: 'Note: The device does not alarm within this range',
        save: 'Save',
        add_device: 'Add device',
        delete_device: 'Delete selected devices',
    },
    order: {
        number: 'Order number',
        account: 'Account name',
        to_paid: 'To be paid',
        paid: 'Paid',
        source: 'Order Source',
        recharge: 'Recharge',
        renewal: 'System renewal',
        amount: 'Amount',
        status: 'Status',
        pay_time: 'Payment time',
    },
    role: {
        title: 'Role Name',
        group: 'Role Group',
        disable: 'Disable',
        enable: 'Enable',
        add: 'Add/Edit Role',
        default: 'Default customer permissions',
        yes: 'yes',
        no: 'no',
        select: 'Select Permissions',
        select_group: 'Please select a role group',
        select_customer: 'Please select the customer you belong to',
        change: 'Change your password',
        phone: 'Phone number',
        reset: 'Reset password',
        last_login: 'Last login time',
    },
    amount: {
        recharge: 'recharge',
        spending: 'Spending',
        type: 'Type',
        auto: 'Automatic call',
        sms: 'SMS notification',
        spending_detail: 'Spending Detail',
    },
    version: {
        title: 'Version',
        update: 'Update',
        download: 'Download URL',
        add: 'Add/Edit',
        apk: 'Install the package',
        upload: 'Upload',
        re_upload: 'Re-upload',
        version_empty: 'Version number cannot be empty',
        update_empty: 'The update prompt content cannot be empty',
        apk_empty: 'Installation package information cannot be empty',
    },
    user: {
        batch_unbind: 'Batch unbinding',
        contact: 'Contact number',
        gender: 'Gender',
        age: 'Age',
        health: 'Health',
        unbind: 'Unbinding',
        unbind_note: 'Confirm the termination of the relationship between IMEI, registered users, and bracelet users?',
        edit: 'Edit user information',
        male: 'Male',
        female: 'Female',
        birthday: 'Birthday',
        height: 'Height',
        weight: 'Weight',
        blood: 'Blood type',
        history: 'History of allergies',
        past: 'Medical history',
        drug: 'Records of drug use',
        good: 'Good',
        normal: 'Normal',
        poor: 'Poor',
        ill: 'Falling ill',
        emergency: 'Emergency contacts',
        call_emergency: 'Emergency contact phone number',
        remark: 'Remark',
        range: 'Normal range',
        nation: 'Nation',
        urgent_relation: 'Relationship',
        current_address: 'Current address',
        id_card: 'ID Number',
        permanent_address: 'Permanent address',
        work_unit: 'Work unit',
        marital_status: 'Marital status',
        smoke_year: 'Smoking years',
        smoke_frequency: 'Smoking frequency',
        drink_year: 'Drinking years',
        drink_frequency: 'Drinking frequency',
        motion_habit: 'Exercise habits',
        eating_habit: 'Eating habits',
        work_rest: 'Work and rest time',
        past_medical_history: 'Past medical history',
        supplement_info: 'Supplementary information',
        married: 'Married',
        unmarried: 'Unmarried',
        divorce: 'Divorce',
        widow: 'Widow',
    },
    pay: {
        amount: 'Recharge amount',
        duration: 'Opening duration',
        one: 'One year',
        two: 'Two years',
        three: 'Three years',
        method: 'Payment method',
        wechat: 'WeChat',
        alipay: 'Alipay',
        back: 'Back',
    },
    center: {
        balance: 'Account balance',
        expire: 'The expiration date of the system',
        renewal: 'Renewal',
        sms: 'SMS notification status',
        call: 'Alert call status',
        update: 'Update',
    },
    ble: {
        floor: 'Floor',
        management: 'Floor management',
        title_empty: 'Title cannot be empty',
        floor_empty: 'The number of floors cannot be empty',
    },
    menu: {
        home: 'Home',
        panel: 'Panel',
        management: 'Devices',
        list: 'Device list',
        details: 'Device details',
        positioning: 'Positioning',
        notice: 'Notifications',
        group: 'Group control',
        fence: 'Electronic fence',
        add_fence: 'Add/Edit fence',
        data: 'Device Data',
        data_list: 'Data list',
        alarm_management: 'Alarm',
        alarm_list: 'Alarm list',
        alarm_setting: 'Alarm settings',
        ble: 'iBeacon',
        building: 'Buildings',
        user: 'Users',
        user_list: 'Device user list',
        stat: 'Statistics',
        system: 'System',
        permission: 'Permission',
        role: 'Role',
        version: 'Version',
        order: 'Order',
        funding: 'Funding details',
        data_panel: 'Panel',
        work: 'Attendance',
        work_place: 'Attendance points',
        work_time: 'Attendance Shift',
    },
    header: {
        re_login: 'Permission information lost, please log in again',
        personal: 'Personal Center',
        logout: 'Log out',
        dltracking: 'Smart doorlock',
    },
    stat: {
        user: 'Total users',
        customer: 'Total customers',
        register: 'Registered Users',
        alarm: 'Number of alarms',
        number: 'Number',
        referrer: 'Referrer',
        rate: 'Online rate',
        anomaly: 'Anomaly statistics',
        growth: 'User growth trend',
        gender: 'User gender statistics',
    },
    panel:{
        install: 'Installing',
        online: 'Online',
        units: 'Connected units',
        offline: 'Offline',
        health: 'Device Health Index',
        today: 'Today\'s alarm processed',
        new: 'New users on the day',
        accumulated: 'Accumulated users',
        heart: 'Heart rate alarm processed',
        daily_abnormal: 'Daily abnormal device',
        product: 'Product classification proportion',
        active: 'User activity ratio',
        blood: 'Blood pressure alarm processed',
        oxygen: 'Blood oxygen alarm processed',
        monthly: 'Monthly abnormal device',
        temp: 'Temperature alarm processed',
        sleep: 'Sleep alarm processed',
        location: 'Location Address',
        final: 'Final alarm',
        last_time: 'Last alarm time',
        normal: 'Normal',
        alarm: 'Alarm',
        abnormal_number: 'Number of abnormal devices',
        bracelet: 'Smart bracelet',
        door_lock: 'Smart door lock',
        unprocessed: 'Unprocessed',
        processed: 'Processed',
        untreated: 'Untreated',
    },
    work: {
        address: 'Address',
        range: 'Range',
        empty: 'This is required',
        confirm: 'Confirm Address',
        not_confirm: 'Please confirm the address information first',
        start_time: 'Work Time',
        end_time: 'Closing Time',
    }
}
export default en
