/* 监听localStorage 变化 */
export const dispatchEventStorage = () => {
    const signSetItem = localStorage.setItem;
    localStorage.setItem = function (key, val) {
        let setEvent = new Event("setItemEvent");
        setEvent.key = key;
        setEvent.newValue = val;
        window.dispatchEvent(setEvent);
        signSetItem.apply(this, arguments);
    };
};
/* 设置localStorage locale 语言版本  */
export const languageSet = () => {
    if (localStorage.getItem("locale")) {
        return localStorage.getItem("locale");
    }
    return localStorage.setItem("locale", "zhCN");
};
