const zhCN = {
    login: {
        title: '登录页',
        system: '云智健康助理管理平台',
        username: '账号',
        password: '密码',
        button: '登录',
        user_empty: '账号不能为空',
        user_length: '账号3-10位',
        password_empty: '密码不能为空',
        password_length: '密码5-16位',
    },
    index: {
        title: '首页',
        panel: '面板',
        device_stat: '设备统计',
        more: '更多',
        device_total: '设备总数',
        new_add_today: '今日新增',
        online: '在线',
        offline: '离线',
        stripping_outline: '摘脱离线',
        alarm_info: '告警信息',
        username: '会员姓名',
        device: '设备IMEI',
        battery_level: '电池电量',
        online_status: '在线状态',
        alarm_type: '告警类型',
        alarm_content: '告警内容',
        alarm_time: '告警时间',
        positioning: '定位',
        device_type: '设备类型',
        device_name: '会员姓名',
        location_info: '位置信息',
        comm_time: '通讯时间',
    },
    device: {
        input_keywords: '请输入筛选关键字',
        add_user: '新增/编辑用户',
        company_name: '公司名称',
        company_empty: '公司名称不能为空',
        parent_customer: '上级客户',
        company_logo: '公司Logo',
        size_desc: '尺寸说明 : 宽400px,高120px',
        data_push: '数据推送配置',
        close: '关闭',
        testing: '测试环境',
        official: '正式环境',
        input_testing: '请输入测试环境地址',
        input_official: '请输入正式环境地址',
        cancel: '取 消',
        confirm: '确 定',
        image_cropping: '图片剪裁',
        devices: '设备列表',
        device_status: '设备状态',
        inquire: '查询',
        reset: '重置',
        export: '导出',
        add: '添加',
        batch_add: '批量添加',
        assign: '分配手环',
        delete: '删除',
        co_search: '共查找',
        device_found: '设备已找到',
        view: '查看',
        not_found_device: '未查找到设备IMEI号',
        vesting: '归属客户',
        creation_time: '创建时间',
        last_comm_time: '最后通讯时间',
        operating: '操作',
        parameter: '参数',
        edit: '编辑',
        add_devices: '添加/编辑设备',
        device_empty: '设备编号不能为空',
        device_type_empty: '设备类型不能为空',
        import_template: '导入模板',
        download: '点击下载',
        input_import: '请上传导入文件',
        import_file: '导入文件',
        upload: '点击上传',
        only_files: '支持xls/xlsx格式文件',
        tips: '提示',
        success_import: '成功导入',
        error_import: '其中错误',
        not_import: '未导入',
        import_tip: '单击“确认”保存错误数据列表，“取消”退出。',
        customer_empty: '目标客户不能为空',
        select_customer: '选择客户',
        device_not_found: '未查到设备IMEI',
        continue_search: '继续查找',
        copy_all: '复制全部',
        device_params: '设备参数设置',
        positioner: '定位器',
        sleep_mode: '睡眠模式',
        open: '开启',
        night_mode: '夜间模式',
        data_upload_interval: '数据上传间隔',
        interval_time: '间隔时间',
        minute: '分',
        second: '秒',
        minutes_error: '不能小于4分钟',
        nb_note: '注 : 该配置下次上传数据生效',
        setting_note: '注: 每个模块内容设置完成，需单独点击发送才可生效',
        second_tip: '以秒为单位下发间隔：不能少于240秒',
        position_note: '备注：室外环境优先选择GPS定位优先；室内环境优先选择WIFI定位优先',
        wifi_priority: 'WIFI优先',
        gps_priority: 'GPS优先',
        position_time: '定位时间',
        active_config: '定时保活配置',
        status: '状态',
        start_time: '开始时间',
        time_example: '时分格式，例如：',
        time: '时间',
        end_time: '结束时间',
        prompt_config: '立即提醒配置',
        remind_type: '提醒类型',
        one_sound: '只响一声',
        keep_sound: '连续不停的响，需按键关闭',
        audio_type: '音频类型',
        urgent_sound: '急促提示音',
        smooth_sound: '平缓提示音',
        vibration: '仅振动',
        time_alarm: '定时闹铃配置',
        send: '发送',
        heart_upload: '心率上传时间，录入范围：00:00-23:59',
        allow_time: '允许测量时间',
        prohibit_time: '禁止测量时间',
        heart_threshold: '心率报警阈值，最小阈值范围：1-50；最大阈值范围：>=90',
        min_threshold: '最小阈值',
        input_threshold: '请输入最小阈值',
        max_threshold: '最大阈值',
        input_max_threshold: '请输入最大阈值',
        distribute_note: '以分钟为单位下发间隔，测量时间间隔范围：1-60；上传时间间隔：1-180',
        measure_interval: '测量时间间隔',
        measure_interval_error: '请输入测量时间间隔',
        upload_interval: '上传时间间隔',
        upload_interval_error: '请输入上传时间间隔',
        select_time: '请选择时间',
        interval_minute: '以分钟为单位下发间隔',
        wifi_note: '备注：默认WIFI优先',
        only_gps: '仅GPS',
        sos_note: '设置SOS号码后，用户按了设备上的SOS按钮，会拨打该电话号码',
        sos: 'SOS号码',
        reboot: '重启设备',
        restore: '设备恢复出厂设置',
        instant: '即时定位',
        gps_timeout: 'GPS超时时间',
        gps_timeout_second: '以秒为单位下发GPS超时时间',
        remote: '远程关机',
        low_battery_alarm: '低电报警开关',
        alarm_time_note: '说明:闹铃时间-开关-频率-类型.开关： 0 关闭 1 开启 频率： 1一次 2每天 3自定义日----周6 类型：\n' +
            '                            周日到周六\n' +
            '                            例如：08:10-1-1,08:10-1-2,08:10-1-3-0111110',
        switch: '开关',
        cycle: '周期',
        sunday: '星期日',
        monday: '星期一',
        tuesday: '星期二',
        wednesday: '星期三',
        thursday: '星期四',
        friday: '星期五',
        saturday: '星期六',
        step_trigger: '设置计步器开关',
        phone_book: 'G36设置电话本 实际包含2个设置电话本指令',
        name: '名称',
        number: '号码',
        unit: '单位: 秒，范围',
        heart_interval: '心率上传间隔',
        measure_heart: '测试心率',
        find: '寻找设备',
        sleep_setting: '睡眠时间段设置',
        temp_note: '体温实时测量下发协议（BT=2，新版）',
        temp_interval: '体温定时上传间隔',
        unit_hour: '单位小时，取值: 1-12(夜间模式不上报)',
        custom_instruction: '自定义指令',
        instruction_content: '指令内容',
        instruction_error: '请输入自定义指令',
        falldown_setting: '跌倒设置',
        call_emergency: '是否呼叫紧急联系人',
        falldown_note: '数值: 数字，范围: 1-8 数字越小，灵敏度越高',
        sensitivity: '当前灵敏度档位',
        suggest: '输入建议5/6',
        reject_phone: '拒绝陌生电话开关',
        timer_on_off: '定时开关机',
        position_mode: '定位模式',
        on_time: '开机时间',
        off_time: '关机时间',
        wifi_watch: 'WIFI手表',
        replicating: '复制成功',
        allocate_success: '分配成功',
        tip: '提示',
        delete_note: '此操作将永久删除该数据, 是否继续?',
        upload_error: '上传图片只能是 JPG/PNG 格式!',
        upload_note: '上传图片大小不能超过 2MB!',
        delete_success: '删除成功',
        upload_success: '上传成功',
        upload_failed: '上传失败',
    },
    detail: {
        overview: '概况',
        admin: '管理人姓名',
        software: '软件版本',
        hardware: '硬件版本',
        signal: '信号',
        volt: '电压',
        step: '步数',
        warning: '告警',
        last_warning: '最近告警内容',
        last_alarm_time: '最近告警时间',
        measurements: '测量值',
        measure_time: '测量时间',
        blood: '血压',
        hrv: 'HRV压力',
        pressure: '压力',
        heart: '心率',
        temp: '体温',
        diastolic: '舒张压',
        systolic: '收缩压',
        oxygen: '血氧',
        lon: '经度',
        lat: '纬度',
        location: '位置',
        position_method: '定位方式',
        exercise: '运动数据',
        calorie: '卡路里',
        move_distance: '运动距离',
        average_step: '平均步数',
        jump_times: '弹跳次数',
        jump_height: '弹跳高度',
        alarm_list: '告警列表',
        detail: '详 情',
        alarm_location: '告警位置',
        trajectory: '轨迹',
        location_failed: '设备定位失败',
    },
    notice: {
        enter_imei: '请输入设备IMEI',
        message: '通知信息',
        input_message: '请输入通知信息',
        submit: '提 交',
    },
    alarm: {
        enter_fence: '进入围栏',
        leaving_fence: '离开围栏',
        sos_alarm: 'SOS报警',
        low_battery: '低电量',
        temperature_alarm: '体温异常',
        heart_alarm: '心率异常',
        blood_alarm: '血压异常',
        removing_alarm: '摘脱异常',
        falldown_alarm: '跌倒报警',
        reminding_alarm: '静止提醒',
        trigger_alarm: '按键触发/拉绳触发',
        reset_alarm: '报警复位',
        dismantle_alarm: '防拆报警',
        pressure_alarm: 'HRV异常',
        main: '总开关',
        notice: '通知开关',
        sound: '声音开关',
        security: '安全告警',
        device: '设备告警',
        health: '健康告警',
        low_temp: '体温偏低',
        high_temp: '体温偏高',
        low_heart: '心率偏低',
        high_heart: '心率偏高',
        high_systolic: '收缩压偏高',
        low_systolic: '收缩压偏低',
        high_diastolic: '舒张压偏高',
        low_diastolic: '舒张压偏低',
        upload: '上传设置',
        confirm: '确认保存告警设置, 是否继续?',
        open: '开',
        close: '关',
        alarm_value: '告警数值',
        safe_range: '安全范围',
        handle: '处理',
        desc: '处理说明',
        handle_time: '处理时间',
        input_desc: '请输入处理说明',
        select_alarm: '请先选择报警信息',
        batch_handle: '批量处理',
    },
    fence: {
        title: '围栏',
        device: '设备',
        userinfo: '用户信息',
        add: '新增围栏',
        name: '围栏名称',
        radius: '半径',
        alarm: '围栏报警',
        type: '围栏类型',
        normal: '水域围栏-普通',
        flood: '水域围栏-汛期',
        safety: '安全围栏',
        construction: '施工围栏',
        alarm_level: '报警等级',
        red: '红',
        orange: '橙',
        yellow: '黄',
        duration: '报警时段',
        status: '围栏状态',
        creator: '创建人',
        shape: '围栏形状',
        circle: '圆形围栏',
        polygon: '多边形围栏',
        details: '围栏详情',
        alarm_type: '报警类型',
        enter: '进入',
        leave: '离开',
        enter_leave: '进入&离开',
        on: '生效',
        off: '失效',
        select_shape: '请选择围栏形状',
        select_type: '请选择围栏类型',
        select_name: '请输入围栏名称',
        select_alarm: '请选择报警类型',
        select_period: '请选择报警时段',
        select_level: '请选择报警等级',
        select_device: '请选择设备信息',
        select_detail: '请输入围栏详情',
        draw_fence: '请绘制围栏信息',
        success: '操作成功',
        clear: '清除围栏',
    },
    health: {
        title: '健康',
        select_date: '选择日期',
        no_data: '暂无数据',
        breathe: '呼吸',
        frequency: '频率',
        times: '次',
        sleep: '睡眠',
        sleep_duration: '睡眠时长',
        shallow: '浅度时长',
        deep: '深度时长',
        clear: '清醒时长',
        shallow_sleep: '浅睡眠',
        deep_sleep: '深睡眠',
        clear_sleep: '清醒',
        sleep_status: '睡眠状态',
    },
    group: {
        alarm_interval: '设置报警区间',
        issue_time: '下发时间',
        operator: '操作员',
        alarm_setting: '报警设置',
        alarm_object: '报警对象',
        alarm_type: '选择报警类型',
        normal_range: '正常值范围',
        max: '最大值',
        min: '最小值',
        select_device: '选择设备',
        alarm_note: '注：在此范围内设备不报警',
        save: '保存',
        add_device: '添加设备',
        delete_device: '删除已选设备',
    },
    order: {
        number: '订单号',
        account: '账户名称',
        to_paid: '待支付',
        paid: '已支付',
        source: '订单来源',
        recharge: '余额充值',
        renewal: '系统续费',
        amount: '金额',
        status: '状态',
        pay_time: '支付时间',
    },
    role: {
        title: '角色名',
        group: '角色组',
        disable: '禁用',
        enable: '启用',
        add: '添加/编辑角色',
        default: '默认客户权限',
        yes: '是',
        no: '否',
        select: '选择权限',
        select_group: '请选择角色组',
        select_customer: '请选择归属客户',
        change: '修改密码',
        phone: '手机号码',
        reset: '重置密码',
        last_login: '最后登录时间',
    },
    amount: {
        recharge: '充值',
        spending: '消费',
        type: '类型',
        auto: '自动呼叫',
        sms: '短信通知',
        spending_detail: '消费描述',
    },
    version: {
        title: '版本号',
        update: '更新提示',
        download: '下载地址',
        add: '添加/编辑',
        apk: '安装包',
        upload: '上传',
        re_upload: '重新上传',
        version_empty: '版本号不能为空',
        update_empty: '更新提示内容不能为空',
        apk_empty: '安装包信息不能为空',
    },
    user: {
        batch_unbind: '批量解绑',
        contact: '联系电话',
        gender: '性别',
        age: '年龄',
        health: '身体状况',
        unbind: '解绑',
        unbind_note: '确认解除IMEI、注册用户、手环用户之间的关系？',
        edit: '编辑用户信息',
        male: '男',
        female: '女',
        birthday: '生日',
        height: '身高',
        weight: '体重',
        blood: '血型',
        history: '过敏史',
        past: '现病史',
        drug: '用药情况',
        good: '良好',
        normal: '一般',
        poor: '较差',
        ill: '疾病',
        emergency: '紧急联系人',
        call_emergency: '紧急联系电话',
        remark: '备注',
        range: '正常范围',
        nation: '民族',
        urgent_relation: '与本人关系',
        current_address: '联系人地址',
        id_card: '身份证号',
        permanent_address: '常住地址',
        work_unit: '工作单位',
        marital_status: '婚姻状况',
        smoke_year: '吸烟年限',
        smoke_frequency: '吸烟频率',
        drink_year: '饮酒年限',
        drink_frequency: '饮酒频率',
        motion_habit: '运动习惯',
        eating_habit: '饮食习惯',
        work_rest: '作息时间',
        past_medical_history: '既往病史',
        supplement_info: '就诊信息',
        married: '已婚',
        unmarried: '未婚',
        divorce: '离异',
        widow: '丧偶',
    },
    pay: {
        amount: '充值金额',
        duration: '开通时长',
        one: '一年',
        two: '两年',
        three: '三年',
        method: '支付方式',
        wechat: '微信',
        alipay: '支付宝',
        back: '返 回',
    },
    center: {
        balance: '账户余额',
        expire: '系统有效期',
        renewal: '续 费',
        sms: '短信通知状态',
        call: '告警呼叫状态',
        update: '更 新',
    },
    ble: {
        floor: '楼层数',
        management: '楼层管理',
        title_empty: '标题不能为空',
        floor_empty: '楼层数不能为空',
    },
    menu: {
        home: '首页',
        panel: '面板',
        management: '设备管理',
        list: '设备列表',
        details: '设备详情',
        positioning: '定位',
        notice: '通知',
        group: '设备群控',
        fence: '电子围栏',
        add_fence: '新增/编辑围栏',
        data: '设备数据',
        data_list: '数据列表',
        alarm_management: '告警管理',
        alarm_list: '告警列表',
        alarm_setting: '告警设置',
        ble: '蓝牙定位',
        building: '建筑物管理',
        user: '会员管理',
        user_list: '会员列表',
        stat: '数据统计',
        system: '系统管理',
        permission: '权限管理',
        role: '角色管理',
        version: '版本管理',
        order: '订单管理',
        funding: '资金明细',
        data_panel: '数据面板',
        work: '考勤',
        work_place: '考勤点',
        work_time: '考勤班次',
    },
    header: {
        re_login: '权限信息丢失，请重新登录',
        personal: '个人中心',
        logout: '退出登录',
        dltracking: '智能门磁',
    },
    stat: {
        user: '用户总数',
        customer: '客户总数',
        register: '注册用户',
        alarm: '报警人数',
        number: '人数',
        referrer: '访问来源',
        rate: '在线比率',
        anomaly: '异常统计',
        growth: '用户新增趋势',
        gender: '用户性别统计',
    },
    panel: {
        install: '安装设备',
        online: '在线设备',
        units: '联网单位',
        offline: '离线设备',
        health: '设备健康指数',
        today: '今日报警已处理',
        new: '当日新增用户',
        accumulated: '累计用户',
        heart: '心率报警已处理',
        daily_abnormal: '每日异常设备',
        product: '产品分类占比',
        active: '用户活跃占比',
        blood: '血压报警已处理',
        oxygen: '血氧报警已处理',
        monthly: '每月异常设备',
        temp: '体温报警已处理',
        sleep: 'SOS报警已处理',
        location: '定位地址',
        final: '最后告警',
        last_time: '最后告警时间',
        normal: '正常',
        alarm: '报警',
        abnormal_number: '异常设备数量',
        bracelet: '智能手环',
        door_lock: '智能门磁',
        unprocessed: '未处理占比',
        processed: '已处理',
        untreated: '未处理',
    },
    work: {
        address: '地址',
        range: '范围',
        empty: '该项为必填项',
        confirm: '确认地址',
        not_confirm: '请先确认地址信息',
        start_time: '上班时间',
        end_time: '下班时间',
    }
}
export default zhCN
