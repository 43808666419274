import {createRouter, createWebHistory} from 'vue-router'

import Layout from '../views/layout'

import tool from "../utils/tool";
import store from '../store'

const routes = [
    {
        path: '/login',
        component: () => import('../views/login'),
        meta: {title: '登录页', hidden: true}
    },
    {
        path: '/three',
        component: () => import('../views/ble/three'),
        meta: {title: 'demo', hidden: true}
    },
    // {
    //     path: '/dwg',
    //     component: () => import('../views/ble/dwg'),
    //     meta: {title: 'demo', hidden: true}
    // },
    {
        path: '/map',
        component: () => import('../views/ble/map'),
        meta: {title: '楼层地图', hidden: true}
    },
    {
        path: '/location_ble',
        component: () => import('../views/ble/location_ble'),
        meta: {title: '蓝牙定位', hidden: true}
    },
    {
        path: '/inspect',
        component: () => import('../views/inspect'),
        meta: {title: '额温枪数据', hidden: true}
    },
    {
        path: '/reader',
        component: () => import('../views/reader'),
        meta: {title: '读卡器日志', hidden: true}
    },
    {
        path: '/gps_test',
        component: () => import('../views/gps_test.vue'),
        meta: {title: 'GPS Test', hidden: true}
    },
    {
        path: '/screen',
        component: () => import('../views/screen.vue'),
        meta: {title: '数字大屏', hidden: true}
    },
    {
        path: '/',
        component: Layout,
        meta: {title: 'menu.home', icon: 'el-icon-s-home'},
        redirect: '/',
        children: [{
            path: '/',
            component: () => import('../views/index.vue'),
            meta: {title: 'menu.panel', icon: 'el-icon-setting', affix: true},
            action: ['index.positioning']
        }]
    },
    {
        path: '/device',
        component: Layout,
        meta: {title: 'menu.management', icon: 'el-icon-s-home'},
        redirect: '/device/home',
        children: [
            {
                path: '/device/home',
                component: () => import('../views/device/home.vue'),
                meta: {
                    title: 'menu.list',
                    icon: 'el-icon-setting',
                    affix: true,
                    action: ['device.add', 'device.batch_add', 'device.assign', 'device.delete', 'device.parameter', 'device.edit']
                }
            }, {
                path: '/device/detail',
                component: () => import('../views/device/detail.vue'),
                meta: {title: 'menu.details', icon: 'el-icon-setting', affix: true, hidden: true}
            }, {
                path: '/device/location',
                component: () => import('../views/device/location.vue'),
                meta: {title: 'menu.positioning', icon: 'el-icon-setting', affix: true}
            }, {
                path: '/device/notice',
                component: () => import('../views/device/notice.vue'),
                meta: {title: 'menu.notice', icon: 'el-icon-setting', affix: true}
            }
        ]
    },
    {
        path: '/group',
        component: Layout,
        meta: {title: 'menu.group', icon: 'el-icon-s-home'},
        redirect: '/group/fence',
        children: [
            {
                path: '/group/fence',
                component: () => import('../views/group/fence.vue'),
                meta: {
                    title: 'menu.fence', icon: 'el-icon-setting', affix: true,
                    action: ['fence.add', 'device.devices', 'device.edit', 'device.delete']
                }
            },
            {
                path: '/group/new_fence',
                component: () => import('../views/group/new_fence.vue'),
                meta: {title: 'menu.add_fence', icon: 'el-icon-setting', affix: true, hidden: true}
            },
            {
                path: '/group/direct',
                component: () => import('../views/group/direct.vue'),
                meta: {title: '指令下发', icon: 'el-icon-setting', affix: true, hidden: true}
            }, {
                path: '/group/alarm',
                component: () => import('../views/group/alarm.vue'),
                meta: {
                    title: 'menu.alarm_setting',
                    icon: 'el-icon-setting',
                    affix: true,
                    action: ['group.alarm_interval', 'device.devices', 'device.delete']
                }
            }
        ]
    },
    {
        path: '/data',
        component: Layout,
        meta: {title: 'menu.data', icon: 'el-icon-s-home'},
        redirect: '/data/home',
        children: [
            {
                path: '/data/home',
                component: () => import('../views/data/home.vue'),
                meta: {
                    title: 'menu.data_list',
                    icon: 'el-icon-setting',
                    affix: true,
                    action: ['detail.detail', 'health.title', 'index.positioning', 'detail.trajectory', 'fence.title']
                }
            },
            {
                path: '/data/health',
                component: () => import('../views/data/health.vue'),
                meta: {title: '健康', icon: 'el-icon-setting', affix: true, hidden: true}
            },
            {
                path: '/data/location',
                component: () => import('../views/data/location.vue'),
                meta: {title: '定位', icon: 'el-icon-setting', affix: true, hidden: true}
            },
            {
                path: '/data/track',
                component: () => import('../views/data/track.vue'),
                meta: {title: '轨迹', icon: 'el-icon-setting', affix: true, hidden: true}
            },
            {
                path: '/data/track_file',
                component: () => import('../views/data/track_file.vue'),
                meta: {title: '轨迹', icon: 'el-icon-setting', affix: true, hidden: true}
            },
            {
                path: '/data/fence',
                component: () => import('../views/data/fence.vue'),
                meta: {title: '围栏', icon: 'el-icon-setting', affix: true, hidden: true}
            }
        ]
    },
    {
        path: '/alarm',
        component: Layout,
        meta: {title: 'menu.alarm_management', icon: 'el-icon-s-home'},
        redirect: '/alarm/home',
        children: [
            {
                path: '/alarm/home',
                component: () => import('../views/alarm/home.vue'),
                meta: {
                    title: 'menu.alarm_list',
                    icon: 'el-icon-setting',
                    affix: true,
                    action: ['alarm.batch_handle', 'index.positioning', 'alarm.handle']
                }
            },
            {
                path: '/alarm/set',
                component: () => import('../views/alarm/set.vue'),
                meta: {title: 'menu.alarm_setting', icon: 'el-icon-setting', affix: true}
            }
        ]
    },
    // {
    //     path: '/ble',
    //     component: Layout,
    //     meta: {title: 'menu.ble', icon: 'el-icon-s-home'},
    //     redirect: '/ble/build',
    //     children: [
    //         {
    //             path: '/ble/build',
    //             component: () => import('../views/ble/build.vue'),
    //             meta: {title: 'menu.building', icon: 'el-icon-setting', affix: true}
    //         }
    //     ]
    // },
    // {
    //     path: '/call',
    //     component: Layout,
    //     meta: {title: '120呼叫记录', icon: 'el-icon-s-home'},
    //     redirect: '/call/home',
    //     children: [
    //         {
    //             path: '/call/home',
    //             component: () => import('../views/call/home.vue'),
    //             meta: {title: '数据列表', icon: 'el-icon-setting', affix: true}
    //         }
    //     ]
    // },
    {
        path: '/user',
        component: Layout,
        meta: {title: 'menu.user', icon: 'el-icon-s-home'},
        redirect: '/user/home',
        children: [
            {
                path: '/user/home',
                component: () => import('../views/user/home.vue'),
                meta: {
                    title: 'menu.user_list',
                    icon: 'el-icon-setting',
                    affix: true,
                    action: ['device.add', 'user.batch_unbind', 'device.edit', 'health.title', 'index.positioning',
                        'detail.trajectory', 'fence.title', 'group.alarm_setting', 'fence.userinfo', 'user.unbind']
                }
            }, {
                path: '/user/app',
                component: () => import('../views/user/app.vue'),
                meta: {title: 'APP用户列表', icon: 'el-icon-setting', affix: true, hidden: true}
            },
            {
                path: '/user/center',
                component: () => import('../views/center.vue'),
                meta: {title: '用户中心', icon: 'el-icon-setting', affix: true, hidden: true}
            },
            {
                path: '/user/pay',
                component: () => import('../views/pay.vue'),
                meta: {title: '支付中心', icon: 'el-icon-setting', affix: true, hidden: true}
            }
        ]
    },
    // {
    //     path: '/work',
    //     component: Layout,
    //     meta: {title: 'menu.work', icon: 'el-icon-s-home'},
    //     redirect: '/work/home',
    //     children: [
    //         {
    //             path: '/work/home',
    //             component: () => import('../views/work/place.vue'),
    //             meta: {title: 'menu.work_place', icon: 'el-icon-setting', affix: true}
    //         },
    //         {
    //             path: '/work/time',
    //             component: () => import('../views/work/time.vue'),
    //             meta: {title: 'menu.work_time', icon: 'el-icon-setting', affix: true}
    //         }
    //     ]
    // },
    {
        path: '/stat',
        component: Layout,
        meta: {title: 'menu.stat', icon: 'el-icon-s-home'},
        redirect: '/stat/home',
        children: [
            {
                path: '/stat/home',
                component: () => import('../views/stat/home.vue'),
                meta: {title: 'menu.stat', icon: 'el-icon-setting', affix: true}
            }
        ]
    },
    {
        path: '/system',
        component: Layout,
        meta: {title: 'menu.system', icon: 'el-icon-s-home'},
        redirect: '/system/user',
        children: [
            {
                path: '/system/user',
                component: () => import('../views/system/user.vue'),
                meta: {
                    title: 'menu.permission',
                    icon: 'el-icon-setting',
                    affix: true,
                    action: ['device.add', 'device.edit', 'device.status', 'role.reset', 'device.delete']
                }
            },
            // {
            //   path: '/system/menu',
            //   component: () => import('../views/system/menu.vue'),
            //   meta: { title: '菜单管理', icon: 'el-icon-setting', affix: true }
            // },
            {
                path: '/system/role',
                component: () => import('../views/system/role.vue'),
                meta: {
                    title: 'menu.role',
                    icon: 'el-icon-setting',
                    affix: true,
                    action: ['device.add', 'device.edit', 'device.status']
                }
            },
            {
                path: '/system/version',
                component: () => import('../views/system/version.vue'),
                meta: {
                    title: 'menu.version', icon: 'el-icon-setting', affix: true,
                    action: ['device.add', 'device.edit', 'device.status']
                }
            },
            // {
            //     path: '/system/order',
            //     component: () => import('../views/system/order.vue'),
            //     meta: {title: 'menu.order', icon: 'el-icon-setting', affix: true}
            // },
            // {
            //     path: '/system/user_amount',
            //     component: () => import('../views/system/user_amount.vue'),
            //     meta: {title: 'menu.funding', icon: 'el-icon-setting', affix: true}
            // }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    try {
        if (from.fullPath === '/screen') {
            window.location.reload()
        }
        if (to.path === '/login' || to.path === '/inspect' || to.path === '/reader') {
            next()
        } else {
            const token = tool.getLocalStorage('token')
            if (tool.isEmpty(token)) {
                next({path: '/login'})
            } else {
                store.commit('SET_LOGIN_STATUS', 1)
                next()
            }
        }
    } catch (err) {
        console.log(err)
    }
})

export default router
