import Cookie from 'js-cookie'

export default {
  isEmpty (v) {
    return v === null || v === undefined || v.toString().trim() === ''
  },
  getLocalStorage (key) {
    let v = null
    v = Cookie.get(key)
    return this.isEmpty(v)?'':v
  },
  saveLocalStorage (key, item, expires = 1) {
    Cookie.set(key, item, {
      expires: expires,
      path: '/'
    })
  },
  clearLocalStorage () {
    let cookies = Cookie.get()
    for (let key of Object.keys(cookies)) {
      Cookie.remove(key)
    }
  },
  removeLocalStorage (key) {
    Cookie.remove(key)
  }
}
