import {createApp} from 'vue'
import App from './App.vue'
// 插件
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import I18n from "@/lang/i18n.js"
// import Modal from '@/components/plugin/modal'

const app = createApp(App)
// 引入插件
app.use(router)
app.use(store)
app.use(I18n);
// Modal(app)
installElementPlus(app)

app.mount('#app')
