import {createStore} from 'vuex'

const online = require('../assets/img/online.png')
const outline = require('../assets/img/outline.png')
const alarm = require('../assets/img/alarm.gif')

const pageAction = localStorage.getItem('page-action') ? JSON.parse(localStorage.getItem('page-action')) : []

export default createStore({
    state: {
        online: online,
        outline: outline,
        alarm: alarm,
        amap_key: 'b6546fb871bbf2543f413ce60f31f0bb',
        amap_version: '1.4.15',
        alarm_imei: '',
        isLogin: 0,
        pageAction: pageAction
    },
    mutations: {
        SET_ALARM_IMEI(state, imei) {
            state.alarm_imei = imei
        },
        SET_LOGIN_STATUS(state, status) {
            state.isLogin = status
        },
        SET_PAGE_ACTION(state, data) {
            state.pageAction = data
        }
    },
    actions: {},
    modules: {}
})
